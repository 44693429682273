var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm.list.length > 0
        ? _c(
            "yd-list",
            {
              staticStyle: { "padding-bottom": "1.3rem" },
              attrs: { theme: "4" },
            },
            _vm._l(_vm.list, function (item, key) {
              return _c(
                "yd-list-item",
                { key: key },
                [
                  _c("img", {
                    attrs: { slot: "img", src: item.img },
                    slot: "img",
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "title",
                      staticStyle: { "margin-top": "0.1rem" },
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                  _c(
                    "yd-list-other",
                    { attrs: { slot: "other" }, slot: "other" },
                    [
                      _c(
                        "div",
                        { ref: "a", refInFor: true, staticClass: "delis" },
                        [
                          _c("em", [_vm._v("订单号:")]),
                          _vm._v(_vm._s(item.order_sn)),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "yd-list-other",
                    {
                      staticStyle: { "margin-top": "0.15rem" },
                      attrs: { slot: "other" },
                      slot: "other",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#f00",
                            width: "100%",
                            "text-align": "right",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.status == 1 ? "未支付" : "已兑换") +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.list.length == 0
        ? _c("div", { staticClass: "nullClass" }, [
            _vm._v("\n    暂无兑换记录\n  "),
          ])
        : _vm._e(),
      _c("tuobenFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }