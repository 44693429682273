<template>
  <div class="page">
    <yd-list style="padding-bottom:1.3rem" theme="4" v-if="list.length > 0">
      <yd-list-item v-for="(item, key) in list" :key="key">
        <img slot="img" :src="item.img" />
        <span slot="title" class="title" style="margin-top:0.1rem">{{
          item.name
        }}</span>
        <yd-list-other slot="other">
          <div class="delis" ref="a"><em>订单号:</em>{{ item.order_sn }}</div>
        </yd-list-other>
        <yd-list-other slot="other" style="margin-top:0.15rem">
          <div style="color:#f00;width: 100%;text-align: right;">
            {{ item.status == 1 ? "未支付" : "已兑换" }}
          </div>
        </yd-list-other>
      </yd-list-item>
    </yd-list>
    <div class="nullClass" v-if="list.length == 0">
      暂无兑换记录
    </div>
    <tuobenFooter></tuobenFooter>
  </div>
</template>

<script>
import tuobenFooter from "@/components/footer/tuobenFooter.vue";
import { getCouponOrders } from "../../services/tuobenLjj";
export default {
  name: "SpecialTopic",
  components: {
    tuobenFooter
  },
  inject: ["reload"],
  data() {
    return {
      list: [],
      heigh: "0"
    };
  },
  created() {
    this.getCouponOrders();
  },
  mounted() {},
  methods: {
    buy(item) {
      console.log(item);
    },
    async getCouponOrders() {
      const res = await getCouponOrders();
      this.list = res.data;
      console.log(res, "ress");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  width: 100%;
  height: 100%;
  /deep/ .yd-list-theme3 .yd-list-item {
    margin-bottom: 0.3rem;
  }
  /deep/ .yd-list-title {
    margin-top: 0.15rem;
  }
  /deep/ .yd-list-theme1 .yd-list-item:last-child {
    margin-bottom: 1.5rem;
  }
  /deep/.yd-list-theme1 .yd-list-item {
    width: 47%;
    float: left;
    margin: 0.1rem;
    padding: 0.1rem;
    border-radius: 0.2rem;
    position: relative;
    border: 0px;
    z-index: 0;
    background-color: #fff;
  }
  .title {
  }
  .delis {
    width: 100%;
    height: 1rem;
    margin-top: 0.1rem;
    line-height: 1rem;
  }
  .bug {
    width: 100%;
    height: 0.4rem;
    line-height: 0.5rem;
    text-align: center;
    margin-top: 0.3rem;
    color: #f44336;
    border-top: 1px solid #eee;
  }
  .nullClass {
    height: 100%;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
